/* @import url('//maxcdn.bootstrapcdn.com/bootstrap/3.2.0/css/bootstrap.min.css'); */

.pagination {
    margin: 5px 0px 0;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
  
.pageInfo {
    margin: 1px;
    text-align: left;
    padding: 1px;
    color: #a0a3bd;
    font-size: 0.874em;
    letter-spacing: 0.5px; 
}
  
.pageButtons {
    display: flex; 
}
  
.pageBtn {
    border: 1px solid  #a0a3bd;
    color:  #a0a3bd;
    border-radius: 3px;
    margin: 3px;
    width: 35px;
    height: 35px; 
}

.activeBtn {
    border: 1px solid blue;
    color: blue;
    background-color: transparent;
}
  
.disabledPageBtn {
    background-color: #a0a3bd;
    cursor: not-allowed;
    opacity: 0.5;
}
  

select.btn-mini {
    height: auto;
    line-height: 14px;
}

.input-xs{
    height: 20px;
    line-height: 1.5;
    font-size: 12px;
    padding: 1px 5px;
    border-radius: 3px;
}