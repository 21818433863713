/***********
* Table Form
************/ 

.custom-table-form td {
  padding:2px; 
} 

table tbody> tr:last-child {

}

.tbl-form{
   height: calc(0.9em + 0.625rem + 0px); 
   font-size: .75rem;
   line-height: 1.3; 
   display: block;
   width: 100%; 
   padding: 0.1375rem 0.19rem; 
   font-weight: 400; 
   color: #2d353c;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #d5dbe0;
   border-radius: 2px; 
}

.custom-table-form  > tbody > tr > td{
 font-size: 10px !important;
 margin:0px;
}

.custom-table-form  > tbody > tr > td > input[type="date"]{ 
 width:110px;
}